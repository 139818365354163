import React from 'react';

import { Formik } from 'formik';
import { navigate } from "@reach/router";

import Layout from '../../../components/layout/Layoutexport-en';

import { StaticImage } from "gatsby-plugin-image";





const siteVerOpts = [

  {
    label: 'International',
    value: '',

  },


  {
    label: 'Ecuador',
    value: '/contactenos/',

  },


];

const langOpts = [

  {
    label: 'EN',
    value: '',

  },


  {
    label: 'ES',
    value: '/export/es/contacto/',

  },


];


const seo={

  title: "Contact Us",
  desc:  "Use the provided form to contact us today. We 'll do our best to get in touch with you right away.",
  image: "",
  pathname: "/export/en/contact/",
  alternateEn: "/export/en/contact/",
  alternateEs: "/export/es/contacto/",
  alternateEsec: "/contactenos/",
  alternateDefault: "/export/es/contacto",

}


const encode = (data) => {

  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

}

const Contacto = ({location}) => {



return(

  <Layout location={location} crumbLabel="Contact"
          siteVerOpts={siteVerOpts} langOpts={langOpts}
          alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
            title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<div className="bg-fixed" >

    <StaticImage src="../../../images/banner-contactenos.jpg"
                className="object-contain"
                alt="Our sales advisors are ready toreceive your request and help you solve all your professional hygiene needs."
                placeholder="blurred"
                layout="fullWidth"
                 />


</div>


<section className="bg-white flex justify-center">
  <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
    <div className="max-w-3xl mx-auto text-center">
      <h1 className="text-4xl font-semibold text-primary-default ">¡Request a quote today!</h1>
    </div>
  </div>
</section>

<section id="contact" className="relative bg-white mt-2">
<div className="relative bg-white">
  <div className="absolute inset-0">
    <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-200"></div>
  </div>
  <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
    <div className="bg-gray-200 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
      <div className="max-w-lg mx-auto">

      <dl className="mt-8 text-2xl text-primary-default font-normal">
          <div>
            <dd>
              <p>Thank you for your interest in Unilimpio S.A. We will be pleased to provide you with more information about our professional hygiene products.</p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
      <div className="max-w-lg mx-auto lg:max-w-none">

      <Formik
        initialValues={{
          nombre: '',
          compania: '',
          pais: '',
          email: '',
          telefono: '',
          mensaje: ''

        }}
        onSubmit={(values, actions) => {

          fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({ "form-name": "lead-En", ...values })
          })
          .then(() => {


          })
          .catch(() => {
            alert('Se produjo un error al procesar su solicitud. Por favor revise la información suministrada y vuelva a intentarlo.}');
          })
          .finally(() => {
            actions.setSubmitting(false)
            navigate(`/export/en/thank-you/`)
          })

        }}
        validate={values => {

          const errors = {};

          if (!values.nombre) {
            errors.nombre = 'Required';
          } else if (values.nombre.length < 5) {
            errors.nombre = 'Please provide your full name.';
          }


          if (!values.compania) {
            errors.compania = 'Required';
          } else if (values.compania.length < 5) {
            errors.compania = 'The supplied information seems to be invalid, please check again.';
          }

          if (!values.pais) {
            errors.pais = 'Required';
          }

          if (!values.email) {
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid e-mail address.';
          }

          if (!values.telefono) {
            errors.telefono = 'Required';
          } else if (values.telefono.length < 6) {
            errors.telefono = 'The supplied information seems to be invalid. Please check again.';
          }

          if (!values.mensaje) {
            errors.mensaje = 'Required';
          } else if (values.mensaje.length < 20 ) {
            errors.mensaje = 'Please provide a more detailed description of your requirment so we can help you better.';
          } else if (values.mensaje.lenght > 500) {
            errors.mensaje = 'Please no more than 500 chars';
          }

          return errors;

        }}

      >
          {formik => (

        <form name="lead-En" onSubmit={formik.handleSubmit} method="POST" data-netlify="true" netlify-honeypot="bot-field" className="grid grid-cols-1 gap-y-6">

        <input type="hidden" name="form-name" value="lead-En" />


          <div>
            <label htmlFor="nombre" className="sr-only">Name</label>
            <input type="text" name="nombre" id="nombre"
                  onChange={formik.handleChange} value={formik.values.nombre}  onBlur={formik.handleBlur}
                  autocomplete="name"
                  className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.nombre && formik.errors.nombre ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                  placeholder="Name"/>
                  {formik.touched.nombre && formik.errors.nombre ? <p className="text-red-500 text-xs italic">{formik.errors.nombre}</p> : null}

          </div>
          <div>
            <label htmlFor="compania" className="sr-only">Company</label>
            <input id="compania" name="compania" type="text"
            onChange={formik.handleChange} value={formik.values.compania}  onBlur={formik.handleBlur}

            autocomplete="company"
            className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.compania && formik.errors.compania ? 'border-red-500' : 'border-gray-300'} rounded-md`}
            placeholder="Company"/>
            {formik.touched.compania && formik.errors.compania ? <p className="text-red-500 text-xs italic">{formik.errors.compania}</p> : null}

          </div>
          <div>
            <label htmlFor="pais" className="sr-only">Country</label>
            <select name="pais" id="pais"
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    autocomplete="country"
                    className={`block w-full shadow-sm sm:text-sm ${formik.touched.pais ? '' : 'text-gray-500'} focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.pais && formik.errors.pais ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                    placeholder="Country"

            >
                  <option value="" selected>Country</option>
                  <option value="Afganistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bonaire">Bonaire</option>
                  <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Canary Islands">Canary Islands</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">Central African Republic</option>
                  <option value="Chad">Chad</option>
                  <option value="Channel Islands">Channel Islands</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos Island">Cocos Island</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote DIvoire">Cote DIvoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaco">Curacao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Ter">French Southern Ter</option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Great Britain">Great Britain</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="India">India</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea North">Korea North</option>
                  <option value="Korea Sout">Korea South</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Midway Islands">Midway Islands</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Nambia">Nambia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherland Antilles">Netherland Antilles</option>
                  <option value="Netherlands">Netherlands (Holland, Europe)</option>
                  <option value="Nevis">Nevis</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau Island">Palau Island</option>
                  <option value="Palestine">Palestine</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Phillipines">Philippines</option>
                  <option value="Pitcairn Island">Pitcairn Island</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Republic of Montenegro">Republic of Montenegro</option>
                  <option value="Republic of Serbia">Republic of Serbia</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="St Barthelemy">St Barthelemy</option>
                  <option value="St Eustatius">St Eustatius</option>
                  <option value="St Helena">St Helena</option>
                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                  <option value="St Lucia">St Lucia</option>
                  <option value="St Maarten">St Maarten</option>
                  <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                  <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                  <option value="Saipan">Saipan</option>
                  <option value="Samoa">Samoa</option>
                  <option value="Samoa American">Samoa American</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Tahiti">Tahiti</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Erimates">United Arab Emirates</option>
                  <option value="United States of America">United States of America</option>
                  <option value="Uraguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City State">Vatican City State</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                  <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                  <option value="Wake Island">Wake Island</option>
                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zaire">Zaire</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
            </select>
            {formik.touched.pais && formik.errors.pais ? <p className="text-red-500 text-xs italic">{formik.errors.pais}</p> : null}

          </div>

          <div>
            <label htmlFor="email" className="sr-only">Email</label>
            <input id="email" name="email" type="email"
            onChange={formik.handleChange} value={formik.values.email}  onBlur={formik.handleBlur}

            autocomplete="email"
            className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md`}
            placeholder="Email"/>
            {formik.touched.email && formik.errors.email ? <p className="text-red-500 text-xs italic">{formik.errors.email}</p> : null}

          </div>
          <div>
            <label htmlFor="telefono" className="sr-only">Phone</label>
            <input type="text" name="telefono" id="telefono"
            onChange={formik.handleChange} value={formik.values.telefono}  onBlur={formik.handleBlur}

            autocomplete="tel"
            className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.telefono && formik.errors.telefono ? 'border-red-500' : 'border-gray-300'} rounded-md`}
            placeholder="Phone"/>
            {formik.touched.telefono && formik.errors.telefono ? <p className="text-red-500 text-xs italic">{formik.errors.telefono}</p> : null}

          </div>

          <div>
            <label htmlFor="mensaje" className="sr-only">Message</label>
            <textarea id="mensaje" name="mensaje"
                      onChange={formik.handleChange} onBlur={formik.handleBlur}
                      aria-describedby="mensaje_description" rows="4" className={`block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 ${formik.touched.mensaje && formik.errors.mensaje ? 'border-red-500' : 'border-gray-300'} rounded-md`}>
                {formik.values.mensaje}
           </textarea>
           {formik.touched.mensaje && formik.errors.mensaje ? <p className="text-red-500 text-xs italic">{formik.errors.mensaje}</p> : null}


          </div>
          <div>
            <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-default hover:bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Contact Us
            </button>
          </div>
          <div className="invisible">
            <label htmlFor="bot-field">
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </div>
        </form>
      )}
 </Formik>
      </div>
    </div>
  </div>
</div>
</section>



<section className="bg-gray-100">
<div className="bg-gray-100">
  <div className="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
    <h2 className="text-3xl font-extrabold text-primary-default">PARENT OFFICE</h2>
    <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
      <div>
        <h3 className="text-2xl font-medium text-primary-lighter">Quito-Ecuador</h3>
        <p className="mt-2 text-xl text-primary-default">
          <span className="block">De los eucaliptos E1-140 y psje. Juncos</span>
          <span className="block">Sector Parque de los recuerdos</span>
          <span className="block">170307</span>
          <span className="block">WhatsApp: +593 98 795 8215</span>
        </p>
      </div>
    </div>
  </div>
</div>
</section>






    </Layout>

  );
}

export default Contacto;
